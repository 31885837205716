import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Strong, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"adblock"} />
		<Helmet>
			<title>
				Donera
			</title>
			<meta name={"description"} content={"The Plugin for the Planet. Raise money for the best climate change projects globally."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
		</Helmet>
		<Section background="--color-primary">
			<Override slot="SectionContent" />
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Image
					src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15:57:09.725Z"
					display="block"
					width="200px"
					sm-width="150px"
					srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 0px 0"
			md-padding="0 0 0px 0"
			sm-padding="10px 0 0px 0"
			background="--color-primary"
			md-height="300px"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Section padding="0px 0 0px 0" sm-padding="20px 0 0 0" background="--color-primary">
					<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
					<Box
						display="flex"
						width="100%"
						flex-direction="column"
						justify-content="center"
						align-items="flex-start"
						lg-align-items="center"
						lg-margin="0px 0px 80px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="0px 0px 0px 0px"
						lg-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
					>
						<Text
							margin="0px 0px 24px 0px"
							color="--light"
							font="--headline1"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							width="70%"
							md-font="normal 900 52px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						>
							Turning off Adblock
						</Text>
						<Text
							margin="0px 0px 40px 0px"
							color="--light"
							font="normal 300 24px/1.5 --fontFamily-sans"
							lg-text-align="center"
							md-font="normal 300 18px/1.5 --fontFamily-sans"
						>
							Donera can only work is you disable your Adblocker. Luckily, turning it off for Donera takes just 30 seconds!
						</Text>
					</Box>
				</Section>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" sm-padding="60px 0 60px 0" sm-flex-direction="row" id="disable-adblocker">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				sm-flex-direction="row"
				sm-order="0"
				margin="0px 0px 0px 0px"
				md-align-self="auto"
				md-justify-content="center"
				md-margin="0px auto 0px auto"
				md-height="fit-content"
				md-flex-direction="row"
				md-flex-wrap="wrap"
			/>
			<Box
				display="flex"
				width="20%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="20%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				md-display="none"
			>
				<Section padding="0px 0 0px 0" sm-padding="60px 0 60px 0">
					<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
					<Box
						display="flex"
						width="100%"
						flex-direction="column"
						justify-content="center"
						align-items="flex-start"
						lg-align-items="flex-start"
						sm-padding="0px 0px 0px 0px"
						padding="0px 0px 0px 0px"
						lg-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						lg-text-align="left"
					>
						<Image
							src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09:22:17.750Z"
							display="block"
							lg-width="150px"
							srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%282%29.png?v=2022-06-24T09%3A22%3A17.750Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Section>
			</Box>
			<Box
				padding="26px 48px 26px 48px"
				margin="0 0 0 0"
				md-max-width="100%"
				sm-padding="24px 12px 36px 12px"
				max-width="90%"
				border-color="--color-dark"
				border-width="0px"
				border-style="solid"
				height="300px"
				align-self="flex-start"
				width="75%"
				md-margin="0 0 0 auto"
				md-width="100%"
				md-height="100%"
			>
				<Structure>
					<Override slot="Content">
						<Override slot="cell-0">
							<Components.QuarklycommunityKitCollapse text-align="center" animFunction="ease-out" duration=".8s">
								<Override slot="Button">
									For Adblock Plus
								</Override>
								<Text margin="0px 0px 0px 0px">
									Start by opening a new tab.
									<br />
									<br />
									Open the ABP plugin and select the gear icon in the top right corner.
									<br />
									<br />
									Navigate to "Allowlist websites." Type "givedonera.org", and click add.
									<br />
									<br />
									ABP should now be allowing Donera.
								</Text>
							</Components.QuarklycommunityKitCollapse>
						</Override>
						<Override slot="cell-1">
							<Components.QuarklycommunityKitCollapse text-align="center" animFunction="ease-out" duration=".8s">
								<Override slot="Button">
									For Adblock
								</Override>
								<Text margin="0px 0px 0px 0px">
									Start by opening a new tab.
									<br />
									<br />
									On the browser toolbar, click the AdBlock hand icon to open its menu.
									<br />
									<br />
									Click the "Don't run on pages on this domain" menu option.
									<br />
									<br />
									An AdBlock settings box will open; click the{" "}
									<Strong>
										Exclude
									</Strong>
									{" "}button.
									<br />
									<br />
									The AdBlock icon will turn green with a thumbs-up sign indicating that it is temporarily off.
								</Text>
							</Components.QuarklycommunityKitCollapse>
						</Override>
						<Override slot="cell-2">
							<Components.QuarklycommunityKitCollapse text-align="center" animFunction="ease-out" duration=".8s">
								<Override slot="Button">
									For Other Adblockers
								</Override>
								<Text margin="0px 0px 0px 0px">
									Start from the Donera new tab screen.
									<br />
									<br />
									On the browser toolbar, select the icon for your ad blocking plugin.
									<br />
									<br />
									Go to setting and then select the whitelist tab.
									<br />
									<br />
									Add the URL "givedonera.org" to your whitelists. Save these changes.
								</Text>
							</Components.QuarklycommunityKitCollapse>
						</Override>
					</Override>
				</Structure>
				<Box min-width="100px" min-height="100px" background="--color-primary" padding="12px 12px 12px 12px">
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 900 36px/1.2 --fontFamily-sans" padding="10px 0px 10px 0px">
						FAQ:
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--light">
						<Strong>
							Q
						</Strong>
						: Why do I need to turn Adblock off?
						<br />
						<Strong>
							A
						</Strong>
						: Donera relies on ads to let anyone raise money for climate change. Adblock prevents ads from being displayed on your device, and so we need your help!
						<br />
						<br />
						<Strong>
							Q
						</Strong>
						: Does turning my Adblock off mean that Donera selling my data?
						<br />
						<Strong>
							A
						</Strong>
						: No. We never sell your data. We don't even track your individual browsing activity. Turning off Adblock is only to let us put ads on your device.
						<br />
						<br />
						<Strong>
							Q
						</Strong>
						: My Adblocker is not shown and I need help whitelisting Donera. Can you help?
						<br />
						<Strong>
							A:
						</Strong>
						{" "}Yes! Email help@givedonera.com and we'll get back to you ASAP.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Box
				display="flex"
				sm-flex-direction="column"
				sm-width="100%"
				sm-text-align="center"
				justify-content="flex-start"
				align-items="center"
			/>
			<Text margin="0px 0px 0px 0px" color="--grey" display="block">
				© Donera. Built with love and tons of tea.
			</Text>
		</Section>
		<Components.QuarklycommunityKitYouTube />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"62958497fedca4001e327b13"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});